// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "PropertyLocationWithHiddenAddress",
              "PropertyLocationWithVisibleAddress"
            ]);
}

var fragmentConverter = {"__root":{"":{"u":"fragment"}}};

var fragmentConverterMap = {
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lon",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyLocationMapInner_location",
  "selections": [
    (v0/*: any*/),
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "CoordinatesV5",
            "kind": "LinkedField",
            "name": "coordinates",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          "action": "THROW"
        }
      ],
      "type": "PropertyLocationWithVisibleAddress",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "CoordinatesV5",
            "kind": "LinkedField",
            "name": "approximateCoordinates",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          "action": "THROW"
        }
      ],
      "type": "PropertyLocationWithHiddenAddress",
      "abstractKey": null
    }
  ],
  "type": "PropertyLocation",
  "abstractKey": "__isPropertyLocation"
};
})());

var wrap_fragment = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment = unwrap_fragment;
exports.wrap_fragment = wrap_fragment;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
