// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var TextLabel__Jsx3 = require("../../../uikit/reason/atoms/Labels/TextLabel__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Colors = require("uikit/styles/colors");

function stringOfExpiring(days_left) {
  if (days_left <= 0) {
    return "Expiring today";
  } else {
    return "Expiring in " + (days_left.toString() + (
              days_left === 1 ? " day" : " days"
            ));
  }
}

function Labels$QuietSellerLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "Quiet Seller"
            });
}

var QuietSellerLabel = {
  make: Labels$QuietSellerLabel
};

function Labels$AnonymousAgentLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "Hidden Agent"
            });
}

var AnonymousAgentLabel = {
  make: Labels$AnonymousAgentLabel
};

function Labels$AnonymousMatchingLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "Anonymous Matching"
            });
}

var AnonymousMatchingLabel = {
  make: Labels$AnonymousMatchingLabel
};

function Labels$QuietBuyerLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "Quiet Buyer"
            });
}

var QuietBuyerLabel = {
  make: Labels$QuietBuyerLabel
};

function Labels$ActiveStatusLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              color: Colors.chelseaCucumber,
              children: "ACTIVE"
            });
}

var ActiveStatusLabel = {
  make: Labels$ActiveStatusLabel
};

function Labels$InactiveStatusLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              color: Colors.mineShaft,
              children: Caml_option.some(props.text)
            });
}

var InactiveStatusLabel = {
  make: Labels$InactiveStatusLabel
};

function Labels$ExpiredStatusLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              color: Colors.crimson,
              children: "EXPIRED"
            });
}

var ExpiredStatusLabel = {
  make: Labels$ExpiredStatusLabel
};

function Labels$ExpiringStatusLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              color: Colors.chelseaCucumber,
              explanationMark: props.expiringIn,
              children: "ACTIVE"
            });
}

var ExpiringStatusLabel = {
  make: Labels$ExpiringStatusLabel
};

function Labels$WithdrawnLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "WITHDRAWN"
            });
}

var WithdrawnLabel = {
  make: Labels$WithdrawnLabel
};

function Labels$SoldLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "SOLD"
            });
}

var SoldLabel = {
  make: Labels$SoldLabel
};

function Labels$RentedLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "RENTED"
            });
}

var RentedLabel = {
  make: Labels$RentedLabel
};

function Labels$RemovedLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "REMOVED"
            });
}

var RemovedLabel = {
  make: Labels$RemovedLabel
};

function Labels$UnderContractLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "UNDER CONTRACT"
            });
}

var UnderContractLabel = {
  make: Labels$UnderContractLabel
};

function Labels$InContractLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "IN CONTRACT"
            });
}

var InContractLabel = {
  make: Labels$InContractLabel
};

function Labels$PurchasedLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "PURCHASED"
            });
}

var PurchasedLabel = {
  make: Labels$PurchasedLabel
};

function Labels$ArchivedLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "ARCHIVED"
            });
}

var ArchivedLabel = {
  make: Labels$ArchivedLabel
};

exports.stringOfExpiring = stringOfExpiring;
exports.QuietSellerLabel = QuietSellerLabel;
exports.AnonymousAgentLabel = AnonymousAgentLabel;
exports.AnonymousMatchingLabel = AnonymousMatchingLabel;
exports.QuietBuyerLabel = QuietBuyerLabel;
exports.ActiveStatusLabel = ActiveStatusLabel;
exports.InactiveStatusLabel = InactiveStatusLabel;
exports.ExpiredStatusLabel = ExpiredStatusLabel;
exports.ExpiringStatusLabel = ExpiringStatusLabel;
exports.WithdrawnLabel = WithdrawnLabel;
exports.SoldLabel = SoldLabel;
exports.RentedLabel = RentedLabel;
exports.RemovedLabel = RemovedLabel;
exports.UnderContractLabel = UnderContractLabel;
exports.InContractLabel = InContractLabel;
exports.PurchasedLabel = PurchasedLabel;
exports.ArchivedLabel = ArchivedLabel;
/* TextLabel__Jsx3 Not a pure module */
