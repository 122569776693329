// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CalendarDate = require("../../../reason/common/types/CalendarDate.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var ClientPropertyMlsStatus_mlsPlan_graphql = require("../../../__generated__/ClientPropertyMlsStatus_mlsPlan_graphql.bs.js");
var ClientPropertyMlsStatus_property_graphql = require("../../../__generated__/ClientPropertyMlsStatus_property_graphql.bs.js");

var convertFragment = ClientPropertyMlsStatus_mlsPlan_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, ClientPropertyMlsStatus_mlsPlan_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientPropertyMlsStatus_mlsPlan_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientPropertyMlsStatus_mlsPlan_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ClientPropertyMlsStatus_mlsPlan_graphql.node, convertFragment, fRef);
}

var MlsPlanFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = ClientPropertyMlsStatus_property_graphql.Internal.convertFragment;

function waitForFragmentData$1(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, ClientPropertyMlsStatus_property_graphql.node, convertFragment$1, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientPropertyMlsStatus_property_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientPropertyMlsStatus_property_graphql.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(ClientPropertyMlsStatus_property_graphql.node, convertFragment$1, fRef);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  waitForFragmentData: waitForFragmentData$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function formatCalendarDate(calendarDate) {
  return DateFns.format(CalendarDate.toLocalDate(calendarDate), "MMMM d");
}

function ClientPropertyMlsStatus$MlsStatus(props) {
  var detailText = props.detailText;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Icon__Jsx3.make, {
                      icon: "Calendar",
                      size: 20
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("span", {
                              children: props.status,
                              className: "font-medium"
                            }),
                        detailText !== undefined ? JsxRuntime.jsx("span", {
                                children: " " + detailText,
                                className: "text-zinc-500"
                              }) : null
                      ],
                      className: "text-lg text-zinc-700"
                    })
              ],
              className: "flex flex-row gap-2 items-center"
            });
}

var MlsStatus = {
  make: ClientPropertyMlsStatus$MlsStatus
};

function ClientPropertyMlsStatus$FromMlsPlan(props) {
  var activeOnMlsSince = props.activeOnMlsSince;
  var mlsPlan = use(props.mlsPlan);
  var onMlsAt;
  if (activeOnMlsSince !== undefined) {
    return JsxRuntime.jsx(ClientPropertyMlsStatus$MlsStatus, {
                status: "On-MLS",
                detailText: "since " + formatCalendarDate(Caml_option.valFromOption(activeOnMlsSince))
              });
  }
  switch (mlsPlan.__typename) {
    case "AlreadyOnMls" :
        return null;
    case "ListOnMlsAsActive" :
        onMlsAt = mlsPlan.onMlsAt;
        break;
    case "ListOnMlsAsComingSoon" :
        onMlsAt = mlsPlan.activeOnMlsOn;
        break;
    case "NoMlsPlan" :
        return JsxRuntime.jsx(ClientPropertyMlsStatus$MlsStatus, {
                    status: "Off-MLS"
                  });
    case "__unselected" :
        return UnselectedUnionMemberError.raise("Unselected mls status " + mlsPlan._0);
    
  }
  return JsxRuntime.jsx(ClientPropertyMlsStatus$MlsStatus, {
              status: "Off-MLS",
              detailText: "until " + formatCalendarDate(onMlsAt)
            });
}

var FromMlsPlan = {
  make: ClientPropertyMlsStatus$FromMlsPlan
};

function ClientPropertyMlsStatus$WithoutErrorBoundary(props) {
  var property = use$1(props.property);
  return JsxRuntime.jsx(ClientPropertyMlsStatus$FromMlsPlan, {
              mlsPlan: property.mlsPlan.fragmentRefs,
              activeOnMlsSince: property.activeOnMlsSince
            });
}

var WithoutErrorBoundary = {
  make: ClientPropertyMlsStatus$WithoutErrorBoundary
};

function ClientPropertyMlsStatus(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "MLS status",
              children: JsxRuntime.jsx(ClientPropertyMlsStatus$WithoutErrorBoundary, {
                    property: props.property
                  })
            });
}

var make = ClientPropertyMlsStatus;

exports.MlsPlanFragment = MlsPlanFragment;
exports.PropertyFragment = PropertyFragment;
exports.formatCalendarDate = formatCalendarDate;
exports.MlsStatus = MlsStatus;
exports.FromMlsPlan = FromMlsPlan;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* date-fns Not a pure module */
