// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Utils = require("../../../utils/Utils.bs.js");
var React = require("react");
var HtmlHolder = require("../../../reason/common/HtmlHolder.bs.js");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Text__Jsx3 = require("../../../uikit/reason/helpers/Text__Jsx3.bs.js");
var View__Jsx3 = require("../../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Buttons__Jsx3 = require("../../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function HtmlDisplayer(props) {
  var __proseStyle = props.proseStyle;
  var proseStyle = __proseStyle !== undefined ? __proseStyle : "sm";
  var match = React.useReducer((function (state, action) {
          if (typeof action !== "object") {
            return {
                    hasOverflow: state.hasOverflow,
                    showingMore: !state.showingMore
                  };
          } else {
            return {
                    hasOverflow: action._0,
                    showingMore: state.showingMore
                  };
          }
        }), {
        hasOverflow: false,
        showingMore: false
      });
  var send = match[1];
  var state = match[0];
  var hasOverflowCallback = React.useCallback((function (hasOverflow) {
          send({
                TAG: "SetHasOverflow",
                _0: hasOverflow
              });
        }), [send]);
  return JsxRuntime.jsxs(View__Jsx3.make, {
              children: [
                JsxRuntime.jsx(Text__Jsx3.WithOverflow.make, {
                      lineClamp: state.showingMore ? undefined : 5,
                      hasOverflowCallback: hasOverflowCallback,
                      children: JsxRuntime.jsx(HtmlHolder.make, {
                            proseStyle: proseStyle,
                            innerHtml: props.text
                          })
                    }),
                state.hasOverflow || state.showingMore ? JsxRuntime.jsx(View__Jsx3.make, {
                        mt: {
                          NAME: "px",
                          VAL: 8
                        },
                        children: Caml_option.some(JsxRuntime.jsxs(Buttons__Jsx3.LinkButton.make, {
                                  onClick: (function (param) {
                                      send("ToggleShowMore");
                                    }),
                                  children: [
                                    JsxRuntime.jsx(Text__Jsx3.make, {
                                          fontSize: {
                                            NAME: "px",
                                            VAL: 16
                                          },
                                          children: (
                                            state.showingMore ? "Read Less" : "Read More"
                                          ) + Utils.$$String.nbsp
                                        }),
                                    JsxRuntime.jsx(Icon__Jsx3.make, {
                                          icon: state.showingMore ? "ArrowUp" : "ArrowDown"
                                        })
                                  ]
                                }))
                      }) : null
              ]
            });
}

var View;

var make = HtmlDisplayer;

exports.View = View;
exports.make = make;
/* Utils Not a pure module */
