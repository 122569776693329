// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../../reason/common/Theme/Theme__Color__Vars.bs.js");
var FragmentErrorBoundary = require("../../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var ClientPropertyLocation_property_graphql = require("../../../__generated__/ClientPropertyLocation_property_graphql.bs.js");
var ClientPropertyLocation_propertyLocation_graphql = require("../../../__generated__/ClientPropertyLocation_propertyLocation_graphql.bs.js");

var convertFragment = ClientPropertyLocation_property_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, ClientPropertyLocation_property_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientPropertyLocation_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientPropertyLocation_property_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ClientPropertyLocation_property_graphql.node, convertFragment, fRef);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = ClientPropertyLocation_propertyLocation_graphql.Internal.convertFragment;

function waitForFragmentData$1(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, ClientPropertyLocation_propertyLocation_graphql.node, convertFragment$1, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientPropertyLocation_propertyLocation_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientPropertyLocation_propertyLocation_graphql.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(ClientPropertyLocation_propertyLocation_graphql.node, convertFragment$1, fRef);
}

var PropertyLocationFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  waitForFragmentData: waitForFragmentData$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function ClientPropertyLocation$Address(props) {
  var locationDisplay = props.locationDisplay;
  var address = props.address;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: address !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: address
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: locationDisplay,
                                    className: "text-lg text-zinc-600"
                                  })
                            ]
                          }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: locationDisplay
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Icon__Jsx3.make, {
                                                  icon: "Lock",
                                                  size: 16,
                                                  color: Theme__Color__Vars.chicago
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: "Hidden Address"
                                                })
                                          ],
                                          className: "flex flex-row items-center gap-1 lg:before:content-['('] lg:after:content-[')']"
                                        }),
                                    className: "text-xl text-zinc-600"
                                  })
                            ]
                          }),
                    className: "flex flex-col lg:flex-row items-baseline gap-2"
                  }),
              className: "text-zinc-700 font-medium text-2xl sm:text-3xl"
            });
}

var Address = {
  make: ClientPropertyLocation$Address
};

function ClientPropertyLocation$ByPropertyLocation(props) {
  var propertyLocation = use$1(props.propertyLocation);
  switch (propertyLocation.__typename) {
    case "PropertyLocationWithHiddenAddress" :
        return JsxRuntime.jsx(ClientPropertyLocation$Address, {
                    address: undefined,
                    locationDisplay: propertyLocation.locationDisplay
                  });
    case "PropertyLocationWithVisibleAddress" :
        return JsxRuntime.jsx(ClientPropertyLocation$Address, {
                    address: propertyLocation.address,
                    locationDisplay: propertyLocation.locationDisplay
                  });
    case "__unselected" :
        return UnselectedUnionMemberError.raise("Unselected property location " + propertyLocation._0);
    
  }
}

var ByPropertyLocation = {
  make: ClientPropertyLocation$ByPropertyLocation
};

function ClientPropertyLocation$WithoutErrorBoundary(props) {
  var property = use(props.property);
  return JsxRuntime.jsx(ClientPropertyLocation$ByPropertyLocation, {
              propertyLocation: property.propertyLocation.fragmentRefs
            });
}

var WithoutErrorBoundary = {
  make: ClientPropertyLocation$WithoutErrorBoundary
};

function ClientPropertyLocation(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "location",
              children: JsxRuntime.jsx(ClientPropertyLocation$WithoutErrorBoundary, {
                    property: props.property
                  })
            });
}

var make = ClientPropertyLocation;

exports.PropertyFragment = PropertyFragment;
exports.PropertyLocationFragment = PropertyLocationFragment;
exports.Address = Address;
exports.ByPropertyLocation = ByPropertyLocation;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* Icon__Jsx3 Not a pure module */
