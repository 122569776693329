// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Utils = require("../../../utils/Utils.bs.js");
var DateFns = require("../DateFns/DateFns.bs.js");
var DateFns$1 = require("date-fns");
var Core__JSON = require("@rescript/core/src/Core__JSON.bs.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var DateFnsTz = require("date-fns-tz");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");

function fromString(str) {
  var rfc3339Regex = /^(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))$/;
  if (rfc3339Regex.test(str)) {
    return {
            TAG: "Ok",
            _0: str
          };
  } else {
    return {
            TAG: "Error",
            _0: str + " is not a valid date."
          };
  }
}

function toString(str) {
  return str;
}

function toLocalDate(t) {
  return DateFns$1.parseISO(t);
}

function fromLocalDate(date) {
  return DateFns.formatISO(date, "date", undefined);
}

function today(tz) {
  return fromLocalDate(DateFnsTz.utcToZonedTime(new Date(), tz));
}

function getDate(t) {
  return Caml_format.int_of_string(t.slice(8, 10));
}

function getMonth(t) {
  return Caml_format.int_of_string(t.slice(5, 7));
}

function getYear(t) {
  return Caml_format.int_of_string(t.slice(0, 4));
}

function encode(prim) {
  return prim;
}

function decode(json) {
  return Core__Result.flatMap(Utils.Result.ofOption("Could not decode " + JSON.stringify(json) + " as a string", Core__JSON.Decode.string(json)), fromString);
}

function format(date) {
  return DateFns$1.format(DateFns$1.parseISO(date), "MMMM dd");
}

exports.fromString = fromString;
exports.toString = toString;
exports.toLocalDate = toLocalDate;
exports.fromLocalDate = fromLocalDate;
exports.today = today;
exports.getDate = getDate;
exports.getMonth = getMonth;
exports.getYear = getYear;
exports.encode = encode;
exports.decode = decode;
exports.format = format;
/* Utils Not a pure module */
