// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css_Color = require("../../../../reason/common/Theme/Css_Color.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Api = require("@react-google-maps/api");

var LoadScript = {};

var Options = {};

var JS = {};

function GoogleMaps$GoogleMap(props) {
  var __center = props.center;
  var __zoom = props.zoom;
  var zoom = __zoom !== undefined ? __zoom : 14;
  var center = __center !== undefined ? __center : ({
        lat: 0,
        lng: 0
      });
  return JsxRuntime.jsx(Api.GoogleMap, {
              id: props.id,
              mapContainerStyle: props.mapContainerStyle,
              mapContainerClassName: props.mapContainerClassName,
              center: center,
              zoom: zoom,
              options: props.options,
              children: props.children
            });
}

var GoogleMap = {
  Options: Options,
  JS: JS,
  make: GoogleMaps$GoogleMap
};

var Marker = {};

function t(strokeColor, strokeOpacity, strokeWeight, fillColor, fillOpacity) {
  return {
          strokeColor: strokeColor,
          strokeOpacity: strokeOpacity,
          strokeWeight: strokeWeight,
          fillColor: fillColor,
          fillOpacity: fillOpacity
        };
}

function make(strokeColor, fillColor) {
  return t(Core__Option.map(strokeColor, Css_Color.toJs), undefined, undefined, Core__Option.map(fillColor, Css_Color.toJs), undefined);
}

var Options$1 = {
  t: t,
  make: make
};

var Circle = {
  Options: Options$1
};

exports.LoadScript = LoadScript;
exports.GoogleMap = GoogleMap;
exports.Marker = Marker;
exports.Circle = Circle;
/* react/jsx-runtime Not a pure module */
