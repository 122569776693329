// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "AlreadyOnMls",
              "ListOnMlsAsActive",
              "ListOnMlsAsComingSoon",
              "NoMlsPlan"
            ]);
}

var fragmentConverter = {"__root":{"ListOnMlsAsComingSoon_activeOnMlsOn":{"b":""},"ListOnMlsAsActive_onMlsAt":{"b":""},"":{"u":"fragment"}}};

var fragmentConverterMap = {
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientPropertyMlsStatus_mlsPlan",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "AlreadyOnMls",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onMlsAt",
            "storageKey": null
          },
          "action": "THROW"
        }
      ],
      "type": "ListOnMlsAsActive",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeOnMlsOn",
            "storageKey": null
          },
          "action": "THROW"
        }
      ],
      "type": "ListOnMlsAsComingSoon",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "willShow",
          "storageKey": null
        }
      ],
      "type": "NoMlsPlan",
      "abstractKey": null
    }
  ],
  "type": "MlsPlan",
  "abstractKey": "__isMlsPlan"
};
})());

var wrap_fragment = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment = unwrap_fragment;
exports.wrap_fragment = wrap_fragment;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
