// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Env = require("../../../reason/common/Env.bs.js");
var Loader = require("../../../uikit/reason/atoms/Loader/Loader.bs.js");
var Css_Color = require("../../../reason/common/Theme/Css_Color.bs.js");
var GoogleMaps = require("../../../uikit/reason/atoms/GoogleMaps/GoogleMaps.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Theme__Color = require("../../../reason/common/Theme/Theme__Color.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../ErrorHandling/FragmentErrorBoundary.bs.js");
var Api = require("@react-google-maps/api");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../ErrorHandling/UnselectedUnionMemberError.bs.js");
var PropertyLocationMap_property_graphql = require("../../../__generated__/PropertyLocationMap_property_graphql.bs.js");
var PropertyLocationMapInner_location_graphql = require("../../../__generated__/PropertyLocationMapInner_location_graphql.bs.js");

var googleKey = Core__Option.getExn(Env.google_key, undefined);

function PropertyLocationMap$Map(props) {
  var __size = props.size;
  var size = __size !== undefined ? __size : "large";
  var match = Api.useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleKey
      });
  var heightPx = size === "medium" ? "h-[380px]" : "h-[480px]";
  return JsxRuntime.jsx("div", {
              children: match.isLoaded ? JsxRuntime.jsx(GoogleMaps.GoogleMap.make, {
                      mapContainerClassName: "flex-grow relative",
                      center: {
                        lat: props.lat,
                        lng: props.lon
                      },
                      options: {
                        controlSize: size === "medium" ? 24 : undefined
                      },
                      children: Caml_option.some(props.children)
                    }) : JsxRuntime.jsx(Loader.make, {}),
              className: heightPx + " flex items-stretch justify-center"
            });
}

var $$Map = {
  googleKey: googleKey,
  make: PropertyLocationMap$Map
};

var convertFragment = PropertyLocationMapInner_location_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, PropertyLocationMapInner_location_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyLocationMapInner_location_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyLocationMapInner_location_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(PropertyLocationMapInner_location_graphql.node, convertFragment, fRef);
}

var PropertyLocationFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function PropertyLocationMap$FromPropertyLocation(props) {
  var size = props.size;
  var propertyLocation = use(props.propertyLocation);
  switch (propertyLocation.__typename) {
    case "PropertyLocationWithHiddenAddress" :
        var match = propertyLocation.approximateCoordinates;
        var lon = match.lon;
        var lat = match.lat;
        return JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(PropertyLocationMap$Map, {
                            lat: lat,
                            lon: lon,
                            size: size,
                            children: JsxRuntime.jsx(Api.Circle, {
                                  center: {
                                    lat: lat,
                                    lng: lon
                                  },
                                  radius: 1000,
                                  options: {
                                    strokeColor: Caml_option.some(Css_Color.toJs(Theme__Color.youtube)),
                                    strokeOpacity: 0.85,
                                    strokeWeight: 2,
                                    fillColor: Caml_option.some(Css_Color.toJs(Theme__Color.youtube)),
                                    fillOpacity: 0.15
                                  }
                                })
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("span", {
                                  children: "To protect seller privacy, location is approximate",
                                  className: "text-sm"
                                }),
                            className: "pt-1 pl-1"
                          })
                    ]
                  });
    case "PropertyLocationWithVisibleAddress" :
        var match$1 = propertyLocation.coordinates;
        var lon$1 = match$1.lon;
        var lat$1 = match$1.lat;
        return JsxRuntime.jsx(PropertyLocationMap$Map, {
                    lat: lat$1,
                    lon: lon$1,
                    size: size,
                    children: JsxRuntime.jsx(Api.Marker, {
                          position: {
                            lat: lat$1,
                            lng: lon$1
                          }
                        })
                  });
    case "__unselected" :
        return UnselectedUnionMemberError.raise("Unselected propertyLocation: " + propertyLocation._0);
    
  }
}

var FromPropertyLocation = {
  PropertyLocationFragment: PropertyLocationFragment,
  make: PropertyLocationMap$FromPropertyLocation
};

var convertFragment$1 = PropertyLocationMap_property_graphql.Internal.convertFragment;

function waitForFragmentData$1(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, PropertyLocationMap_property_graphql.node, convertFragment$1, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyLocationMap_property_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyLocationMap_property_graphql.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(PropertyLocationMap_property_graphql.node, convertFragment$1, fRef);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  waitForFragmentData: waitForFragmentData$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function PropertyLocationMap$WithoutErrorBoundary(props) {
  var property = use$1(props.property);
  return JsxRuntime.jsx(PropertyLocationMap$FromPropertyLocation, {
              propertyLocation: property.propertyLocation.fragmentRefs,
              size: props.size
            });
}

var WithoutErrorBoundary = {
  make: PropertyLocationMap$WithoutErrorBoundary
};

function PropertyLocationMap(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "map",
              children: JsxRuntime.jsx(PropertyLocationMap$WithoutErrorBoundary, {
                    property: props.property,
                    size: props.size
                  })
            });
}

var make = PropertyLocationMap;

exports.$$Map = $$Map;
exports.FromPropertyLocation = FromPropertyLocation;
exports.PropertyFragment = PropertyFragment;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* googleKey Not a pure module */
